@import '../../../../../styles/basics';

.popup-social-media-side-share {
  > :global(.container) {
    padding-top: 0;
    padding-bottom: 0;
  }

  > .popup-navigation {
    display: none;
  }

  &__contents {
    position: relative;
    background-color: $white;
    max-width: 780px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    padding: 44px 24px;
    @include media-breakpoint-up(md) {
      padding: 80px 32px;
    }

    // IE11 doesn't understand auto height
    &--ie11-compat {
      height: 360px;
    }

    &-close-popup {
      position: absolute;
      cursor: pointer;
      background-image: url('/static/icons/close-green.svg');
      border-radius: 100%;
      height: 36px;
      width: 36px;
      background-repeat: no-repeat;
      background-size: 100%;
      top: -18px;
      right: 24px;
      @include media-breakpoint-up(md) {
        height: 44px;
        width: 44px;
        top: -22px;
      }

      &:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
          url('/static/icons/close-green.svg');
        background-size: 100%;
      }
    }

    &-title {
      margin-bottom: 12px;
    }

    &-form {
      justify-content: space-between;
      display: block;
    }

    p {
      margin-bottom: 24px;
      @include media-breakpoint-up(md) {
        margin-bottom: 32px;
      }
    }

    h6 {
      margin-bottom: 12px;
      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }
    }

    :global(.field-url) {
      flex-grow: 1;
      margin-bottom: 12px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      input {
        border: solid 1px $slate-600;
        background: $white;
        color: $gray-1000;
        font-size: 14px;
      }
    }

    button {
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        margin-left: 20px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__contents {
      &-form {
        display: flex;
      }
    }
  }
}
