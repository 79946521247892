@import '../../../../../styles/basics';

.bb-social-media-side-share-desktop {
  position: absolute;
  z-index: 6750;
  background-color: $white;
  width: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  display: none;

  &--sticky {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
  }

  > * {
    margin-bottom: 20px;
  }

  > mark {
    display: block;
    background-color: $turquoise-1000;
    color: $white;
    padding: 8px 12px;
  }

  &__provider-link {
    justify-content: center;
    display: flex;

    svg {
      width: auto;
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}
