@import '../../../../../styles/basics';

.bb-social-media-side-share-mobile {
  text-align: center;

  &--bordered {
    border: none;
    border-top: solid 1px $slate-200;
    padding-top: 32px;
    @include media-breakpoint-only(md) {
      border-bottom: solid 1px $slate-200;
    }
  }

  &__providers {
    margin: auto;
    max-width: 240px;
    margin-top: 16px;
    margin-bottom: 32px;
    justify-content: space-between;
    display: flex;

    &-link {
      display: flex;
      height: 36px;
      width: 36px;
      border-radius: 100%;
      border: solid 1px $turquoise-1000;
      transition: border-color 0.2s;

      &:hover {
        border-color: $turquoise-1200;
      }

      svg {
        margin: auto;
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}
